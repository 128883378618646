import React, { useState } from "react";
import classNames from "classnames";
import classes from "./burger-button.module.scss";

function BurgerButton({ onClick, className }) {
   const [isActive, setIsActive] = useState(false);
   const [isClicked, setIsClicked] = useState(false);

   const clickButton = (isActive) => {
      setIsActive(isActive);
      setIsClicked(isActive);
      if (onClick) {
         onClick(isActive);
      }
   };

   return (
      <button
         className={classNames(classes.menuBtn, className, [
            { [`${classes.isActive}`]: isActive },
            { [`${classes.isClicked}`]: isClicked },
         ])}
         onClick={() => clickButton(!isActive)}
         aria-labelledby="sr-only"
      >
         <span className={classes.srOnly} id="sr-only">
            Menu
         </span>
         <span className={classes.menuBtn__icon} aria-hidden="true">
            <span className={classNames(classes.icon, classes.iconMenu)}>
               <span></span>
               <span></span>
               <span></span>
            </span>
            <span className={classNames(classes.icon, classes.iconClose)}>
               <span></span>
               <span></span>
            </span>
         </span>
      </button>
   );
}

export default BurgerButton;
