import React from "react";
import footerClasses from "./footer.module.scss";
import LogoFooter from "./footer-logo";
import FacebookIcon from "src/components/facebook-icon";
import {Link} from "gatsby"
import classNames from "classnames";
import homeClasses from "../../pages/home.module.scss";
import {BUSINESS_CONDITIONS, PRIVACY_POLICY} from "../../constants/homepage"

const Footer = function () {
    return (
        <footer className={footerClasses["footer"]} >
            <div className={homeClasses["wrapper"]}>
                <div className={footerClasses["footerWrapper"]}>
                    <div className={classNames(footerClasses["separator"], footerClasses["companySection"])}>
                        <div className={footerClasses["logo"]}>
                            <LogoFooter/>
                        </div>
                        <span>(C) 2021 CAREE s.r.o.</span>
                    </div>
                    <div className={footerClasses["separator"]}>
                        <Link className={"link"} to={"#"}>Preprava zásielok v rámci Košíc</Link>
                        <Link className={"link"} to={"#"}>Preprava zásielok z Košíc do Česka</Link>
                    </div>
                    <div>
                        <a className={"link"} href={PRIVACY_POLICY} target={'_blank'}>Súkromie</a>
                        <a className={"link"} href={BUSINESS_CONDITIONS} target={'_blank'}>Obchodné podmienky</a>
                    </div>
                    <div className={footerClasses["facebookIcon"]}>
                        <a target="_blank" href="http://facebook.com/doructo">
                            <FacebookIcon/>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;
