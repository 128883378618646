import React from "react";

function Logo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="142"
            height="27"
            fill="none"
            viewBox="0 0 142 27"
        >
            <g fill="#F04E23" clipPath="url(#clip0)">
                <path d="M20.2 26.1h-5.9v-2.4c-1.3 1.7-3.2 2.7-5.6 2.7-5.2 0-8.7-3.9-8.7-9.6 0-5.7 3.4-9.4 8.5-9.4 2.4 0 4.4 1 5.7 2.7V.6h5.9v25.5h.1zm-6-9.2c0-2.9-1.7-4.8-4.1-4.8-2.5 0-4.1 2-4.2 4.8 0 2.9 1.7 4.8 4.2 4.8 2.5.1 4.1-1.9 4.1-4.8zM52.3 10.7c1.3-2.2 3.4-3.4 6-3.4v5.4c-3.4-.3-5.7 1.3-6 3.9v9.5h-5.9V7.6h5.9v3.1zM78.7 26.1h-5.9V23c-1.3 2.2-3.3 3.3-6 3.3-4 0-6.6-2.8-6.6-7.1V7.6H66v10c0 2.2 1.2 3.5 3.1 3.5 2.3 0 3.6-1.9 3.6-4.4V7.6h5.9v18.5h.1zM99.4 11.6l-4.3 2.6c-.9-1.2-2.1-1.9-3.7-1.9-2.3 0-3.9 1.9-3.9 4.7 0 2.9 1.6 4.7 3.9 4.7 1.7 0 3-.7 3.8-2.1l4.3 2.5c-1.5 2.7-4.5 4.3-8.4 4.3-5.7 0-9.6-3.8-9.6-9.4 0-5.7 3.9-9.5 9.6-9.5 3.8-.1 6.7 1.4 8.3 4.1zM88.6 0l2.1 3.2L92.8 0h4.6l-4.2 5.6H88L83.9 0h4.7zM107.3 23.5c0 1.7-1.2 2.9-2.9 2.9s-2.9-1.2-2.9-2.9 1.2-2.9 2.9-2.9c1.7.1 2.9 1.2 2.9 2.9zM120.5 25.1c-1.3.8-3.2 1.3-5 1.3-3.6 0-6.2-2-6.2-5.9v-8.2h-2.6V8.5h2.6V3.3h5.9v5.1h4.8v3.9h-4.8v7.3c0 1.4.6 2.1 1.7 2 .6 0 1.4-.2 2.3-.6l1.3 4.1zM141.7 16.8c0 5.7-4.1 9.5-10.1 9.5-6.1 0-10.1-3.8-10.1-9.5s4.1-9.5 10.1-9.5c6.1.1 10.1 3.8 10.1 9.5zm-14.2.1c0 2.9 1.7 4.8 4.2 4.8 2.4 0 4.1-1.9 4.1-4.8s-1.7-4.8-4.1-4.8c-2.6 0-4.2 2-4.2 4.8zM33.3 7.4c-6.1 0-10.1 3.7-10.1 9.5 0 5.7 4.1 9.5 10.1 9.5 6.1 0 10.1-3.8 10.1-9.5.1-5.8-4-9.5-10.1-9.5zm6.5 10.3l-5.7 4.4c-.2.2-.4.2-.7.2-.2 0-.3 0-.5-.1-.4-.2-.6-.6-.6-1v-1.8h-4.6c-.8 0-1.4-.6-1.4-1.4v-2.6c0-.8.6-1.4 1.4-1.4h4.6v-1.8c0-.4.2-.8.6-1 .4-.2.8-.1 1.2.1l5.7 4.4c.3.2.4.5.4.9 0 .6-.1.9-.4 1.1z"></path>
            </g>
            <defs>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0H141.7V26.4H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Logo;
