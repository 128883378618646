import React from "react";
import classes from "./day-time.module.scss";
import PropTypes from 'prop-types'
import classNames from "classnames";

const DayTime = function ({Text, isFrameTime}) {

    return (
        <>
        {Text ?  <Text/> : (<span   className={classNames(classes.dayTime, {
            [classes.activeTime]: isFrameTime,
            [classes.notActiveTime]: !isFrameTime
        })} >
            10:00 - 18:00
        </span>)}
        </>
    
    );
};
DayTime.propTypes = {
    isFrameTime: PropTypes.bool,
}

export default DayTime;
