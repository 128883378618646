import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import headerClasses from "./header.module.scss";
import Logo from "src/components/logo";
import FacebookIcon from "src/components/facebook-icon";
import BurgerButton from "src/components/burger-button";
import classNames from "classnames";
import DayTime from "../day-time";
import { CONTACT_US_ID } from '../../constants/contact-us'
import { HOUR_TO_PICKUP } from '../../constants/homepage'
import { isActivePickupDeliveryTime } from 'src/helpers/additionalFuctions'

const Header = function ({showDayTime, showNav, showFacebookIcon, page = "home", hiddenContact}) {
  const [openNavbar, setOpenNavbar] = useState(false);
  return (
    <header className={classNames(headerClasses["header"], headerClasses[page], {[`${headerClasses["openMobileNavbar"]}`]: openNavbar})}>
        <div className={headerClasses["container"]}>
            <div className={headerClasses["content"]}>
                <div className={headerClasses["logo"]}>
                    <Link to="/">
                        <Logo />
                    </Link>
                </div>
                {
                    showDayTime && (<div className={headerClasses["mobileDayTime"]}>
                        <DayTime isFrameTime={isActivePickupDeliveryTime()}/>
                    </div>)
                }
                {
                    showNav && <nav>
                        <ul>
                            <li>
                                <Link to="/faq">Časté otázky</Link>
                            </li>
                            <li>
                                <Link to="/for-companies" >Pre firmy</Link>
                            </li>
                        </ul>
                        <ul className={headerClasses.info}>
                            <li className={headerClasses["dayTimeOption"]} >
                                <DayTime isFrameTime={  isActivePickupDeliveryTime()} />
                            </li>
                            <li className={classNames({[`${headerClasses["contactLink"]}`]: hiddenContact})}>
                                <Link to={`#${CONTACT_US_ID}`}>Kontakt</Link>
                            </li>
                        </ul>
                    </nav>
                }


                <BurgerButton className={headerClasses["burgerButton"]} onClick={setOpenNavbar} />
                {
                    showFacebookIcon && <div className={headerClasses["social"]}>
                        <a target="_blank" href="http://facebook.com/doructo" >
                            <FacebookIcon/>
                        </a>
                    </div>
                }
            </div>
        </div>
    </header>
  );
};

Header.propTypes = {
    showDayTime: PropTypes.bool,
    showNav: PropTypes.bool,
    showFacebookIcon: PropTypes.bool,
};
Header.defaultProps = {
    showDayTime: true,
    showNav: true,
    showFacebookIcon: true,
};

export default Header;
